.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.submit{
  margin-left:125%;
}

.container-nosotros{
  overflow: hidden;
}

.footer-contact a{
  padding: 15px;
}
.footer-contact a:hover{
  filter: drop-shadow(0px 0px 2.8px #f3f3f3);
}

.ban {
  box-shadow: 5px 5px 5px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 90%;

}

.banner {
  display: flex;
  width: 200%; 
  animation: desplazamiento 55s linear infinite;
}

@keyframes desplazamiento {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


/* Optional: Add active styles for better visualization */
.form-img.active {
  border: 2px solid green; /* Add a border or any other style for active images */
}







.container-cloud {
  /* Basic dimensions and centering */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Dark mode colors and gradient */
  background: #121212; /* Fallback for browsers that don't support gradients */
  background: linear-gradient(
    135deg,
    #121212 25%,
    #1a1a1a 25%,
    #1a1a1a 50%,
    #121212 50%,
    #121212 75%,
    #1a1a1a 75%,
    #1a1a1a
  );
  background-size: 40px 40px;

  /* Animation */
  animation: move 4s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.ver:hover {
  transition: ease 2ms all;
  border-bottom: .5px solid white;
} 
.verContact:hover{
  transition: ease 2ms all;
  border-bottom: .5px solid rgb(169, 20, 223); 
}

@media only screen and (max-width: 800px) {
  .submit{
    margin-left:0%;
  }
  
  .container-nosotros{
    overflow: auto;
  }
}




@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 40px;
  }
}
